@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Poppins', sans-serif !important;

  /* transition: 1.15s linear all; */
}
:root{
  --main-color:#19224C ;
  --secondary-color:#eeeeee ;
  --link-color:#00B3FF ;
}

.inputType{
  border-radius: 4px;
  border: none;
  padding: 16px 12px 16px 12px
  ;
  height: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
.inputPhone{
  border-radius: 4px;
  border: none;
  padding: 0
  ;
  height: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}
.inputTypeContainer.focused {
  border-color: var(--link-color);
}
.inputTypeContainer{
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
  /* padding: 16px 12px 16px 12px */
  ;
  height: 40px;
  outline: none;
}
.custom-datepicker {
  width: 100%;
  border-radius: 4px;
  height: 40px;
  padding: 0 14px;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  background-color: var(--bs-app-footer-bg-color);
  color: var(--bs-body-color);
  outline: none;

}

.disabled {
  background-color: rgba(216, 216, 216, 0.342);
  border: 1px solid #54565c6b;
}
.css-5c7i73-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,.css-5c7i73-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled, .Mui-error):before,.css-5c7i73-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled, .Mui-error):before{
  border-bottom: none !important;
}
.css-1gjkpb-MuiInputBase-root-MuiInput-root-MuiSelect-root:before{
  border-bottom: none !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.apexcharts-legend-series{
  margin: 0 12px !important;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, 
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: flex-start !important;
  margin-left: 250px;
  z-index: 120;
  top: 7px !important;
  display: none;
}

/* :dir(rtl) .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, 
:dir(rtl) .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  margin-left: 0;
  margin-right: 250px;
  justify-content: flex-start !important;

} */
.apexcharts-toolbar{
  display: none !important;
}
input[type='tel'] {
  border: none !important;
  background-color: #f5f8fa00 !important;
  transition: 1s linear all;
  font-weight: 400 !important;
}
p{
  margin-bottom: 0 !important;
}
[data-bs-theme='dark'] .PhoneInputInput {
  background-color: #1e1e2d00 !important;
}
.PhoneInputInput {
  background-color: #1e1e2d00 !important;
}
.custom-box,
.css-13cymwt-control {
  background-color: rgb(255, 255, 255);
  /* color: #1b1b29 !important; */
}
[data-bs-theme='dark'] .custom-box,
[data-bs-theme='dark'] .css-13cymwt-control {
  background-color: #1e1e2d;
  color: rgb(0, 0, 0);
  border: 1px solid #323248;
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: 1px solid var(--bs-gray-300) !important;
}
.ql-editor {
  height: 224px !important;
}
.actions {
  background-color: #4b6fed !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px !important;
  display: flex !important;
  min-height: 0 !important;
  padding: 3px 2px 2px 2px !important;
}
.css-yj6ago-MuiSpeedDial-root .MuiSpeedDial-actions {
  flex-direction: row !important;
}
input,
select {
  outline: none;
}

.Variations-Btn {
  color: var(--bs-gray-500);
  border: 1px solid var(--bs-gray-500);
  background: rgba(255, 0, 0, 0);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  padding: 0 15px;
}
.upload-box-img:hover .upload-box-img-hover {
  display: flex;
}
.sun-editor {
  border: 1px solid var(--bs-gray-300) !important;
}
.sun-editor .se-btn-tray {
  background: var(--bs-app-footer-bg-color) !important;
  border-radius: 6.18px 6.18px 0 0;
}
.sun-editor,
.sun-editor .se-container,
.sun-editor .se-toolbar {
  border-radius: 6.18px 6.18px 0 0;
}
.sun-editor .se-wrapper .se-wrapper-inner {
  background: var(--bs-app-footer-bg-color) !important;
  border: 1px solid var(--bs-gray-300) !important;
}
.sun-editor .se-resizing-bar .se-navigation {
  background: var(--bs-app-bg-color) !important;
  border: 1px solid var(--bs-gray-300) !important;
}
.sun-editor .se-tooltip {
  color: var(--bs-body-color) !important;
  border: 1px solid var(--bs-gray-300) !important;
}
.sun-editor .se-toolbar {
  outline: 1px solid var(--bs-gray-300) !important;
}
.sun-editor .se-btn-module-border {
  border: 1px solid var(--bs-gray-300) !important;
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  min-height: 307px !important;
  color: var(--bs-body-color) !important;
}
.upload-box-img-hover {
  display: none;
}
.app-sidebar .menu .menu-item .menu-link.active {
  background-color: rgba(216, 25, 34, 0.1) !important;
  color: #d81922 !important;
}
.form-check-input:checked {
  background-color: #d81922 !important;
}
.app-sidebar .menu .menu-item .menu-link .menu-title {
  color: var(--bs-body-color) !important;
}
.app-sidebar .menu .menu-item .menu-link.active .menu-title {
  color: #d81922 !important;
}
.app-sidebar .menu .menu-item .menu-link {
  color: var(--bs-body-color) !important;
}
.input-code {
  color: red;
  border: 1px solid #e1e3ea;
}
.input-code:focus {
  border: 1px solid var(--link-color);
  color: red;
}
.login-input:focus {
  background-color: var(--bs-body-bg);
}
/* .select-lang .css-5c7i73-MuiInputBase-root-MuiInput-root-MuiSelect-root:before{
  border-bottom: 1px solid var(--bs-app-footer-bg-color) !important
 } */

/* ======================== */
.rdrDateDisplayWrapper,
.rdrMonthAndYearWrapper,
.rdrMonth,
.rdrStaticRanges,
.rdrDefinedRangesWrapper {
  background-color: var(--bs-app-footer-bg-color) !important;
}
.rdrDayNumber span {
  color: var(--bs-body-color) !important;
}
.rdrDayPassive .rdrDayNumber span {
  color: #a1a5b7 !important;
}
.rdrDateDisplayItemActive,
.rdrDateDisplayItem,
.rdrStaticRangeLabel {
  background-color: var(--bs-app-footer-bg-color) !important;
  border: 1px solid #a1a5b700 !important;
  color: var(--bs-body-color) !important;
}
.Toastify__toast-container {
  width: 100vw !important;
  position: fixed;
  top: 24px !important;
  left: 0px;
  transform: translateX(-50%);
  height: 25px;
  box-shadow: none;
}
.Toastify__toast-theme--light {
  background: #c0d3d245 !important;
  color: white !important;
  height: 10px !important;
}
.Toastify__toast {
  min-height: 10px !important;
  height: 70px !important;
}
.Toastify__close-button {
  display: none;
}
.Toastify__toast-body {
  justify-content: center !important;
}
.Toastify__toast-body > div:last-child {
  flex: initial !important;
  color: #00953c;
  font-weight: 600;
}
.Toastify__toast--error .Toastify__toast-body > div:last-child {
  color: #d81923;
}
.Toastify__toast--error {
  background-color: rgba(216, 25, 35, 0.1) !important;
}
/* =================================== */

*:not(i) {
  /* font-family: "Roboto", sans-serif !important; */
  font-weight: 400;
}
.invo .ft-poppins-med {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}
.invo .ft-poppins-reg {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}
.invo .container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  color: #262626;
}

.invo .head {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.invo p {
  margin: 8px 0;
}

.invo .bold {
  font-weight: 600;
}

.invo h2 {
  font-size: 3rem;
  color: #333;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 30px 0 15px;
}

.invo .invoice,
.totalPricing {
  font-size: 48px !important;
  font-weight: 500 !important;
}

.invo .fs-14px {
  font-size: 14px !important;
}

.invo .fs-15-med {
  font-size: 15px !important;
  font-weight: 500 !important;
}
.invo .fs-18-med {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.invo .fs-16-med {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.invo .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invo .flex-c {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.invo .flex-wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  font-size: 16px;
}

.invo .flex-wrap-new {
  flex-wrap: wrap;
}

.invo .sec {
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}

.invo .pl-20 {
  padding-left: 20px !important;
}

.invo .table {
  width: 100%;
}

.invo .table table {
  width: 100%;
  border-collapse: collapse;
}

.invo .table table thead {
  text-align: center;
}

.invo .table table thead th {
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

.invo .table table tbody tr {
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

.invo .table table tbody tr:last-child {
  border-bottom: none;
}

.invo .table table tbody td {
  text-align: center;
  padding: 20px 0;
}

.invo .table table tbody td img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
}

.invo .product-name {
  text-align: start;
  font-size: 16px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.invo .billing table {
  width: 100%;
}

.invo .billing tr td {
  padding: 8px 0;
}

.invo .billingDetails {
  margin-top: 80px;
  width: 300px;
}

.invo .w-100 {
  width: 100%;
}

.invo .border-top {
  border-top: 1px solid #e0e0e0;
}

.invo .text-gray {
  color: #4f4f4f;
}

.invo .d-none {
  display: none !important;
}
.invo .d-block {
  display: block !important;
}
.invo .flex-column {
  flex-direction: column !important;
}
.invo .d-flex {
  display: flex !important;
}

.invo .align-items-center {
  align-items: center !important;
}
.invo .justify-content-center {
  justify-content: center !important;
}

.invo .flex-wrap {
  flex-wrap: wrap !important;
}

.invo .text-center {
  text-align: center !important;
}
.invo .max-w-262px {
  max-width: 262px;
}
.invo .max-w-420px {
  max-width: 420px;
}
.invo .max-w-350px {
  max-width: 420px;
}
.invo .max-w-600px {
  max-width: 600px;
}
.invo .align-items-flex-start {
  align-items: flex-start;
}

.invo .text-2d {
  color: #2d3748 !important;
}

.invo .fs-18px {
  font-size: 18px !important;
}

.invo .align-items-start {
  align-items: start;
}
.invo .justify-content-start {
  justify-content: start;
}

.invo .summaryBorder {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .invo .d-sm-block {
    display: block !important;
  }
  .invo .d-sm-none {
    display: none !important;
  }
  .invo .flex-sm-row {
    flex-direction: row !important;
  }
  .invo .text-sm-start {
    text-align: left !important;
  }
}
.invo .billing {
  display: flex;
  justify-content: end;
}

.invo .pl-fr {
  padding-left: 10px;
  flex-wrap: wrap;
}

.invo .invoice-logo {
  width: 112px;
  height: 108px;
}
.invo .rog-logo {
  width: 150px;
  height: 65px;
}

.invo .text-align-left-width-350px {
  text-align: left;
  width: 350px;
}
/* =========================== */
.invo .text-left {
  text-align: left !important;
}
.invo .text-right {
  text-align: right !important;
}
.invo .padding-left-60px {
  padding-left: 60px !important;
}
.invo .footer {
  max-width: 150px;
  margin-bottom: 150px;
  margin-right: auto;
}

.invoAr .text-left {
  text-align: right !important;
}
.invoAr .text-right {
  text-align: left !important;
}
.invoAr .padding-left-60px {
  padding-right: 60px !important;
}
.invoAr .footer {
  max-width: 150px;
  margin-bottom: 150px;
  margin-left: auto;
}

.web-auto {
  text-align: -webkit-auto;
}

.text-startIn {
  text-align: start !important;
}
.text-web {
  text-align: -webkit-auto !important;
}
.ql-editor .ql-video {
  width: 100%;
}
/* .custom-datepicker {
  width: 100%;
  border-radius: 8px;
  height: 48px;
  padding: 0 14px;
  border: 1px solid var(--bs-gray-300);
  cursor: pointer;
  background-color: var(--bs-app-footer-bg-color);
  color: var(--bs-body-color);
} */

.disabled {
  background-color: rgba(216, 216, 216, 0.342);
  border: 1px solid #54565c6b;
}
.filter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background: #ffffff00;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 18.525px;
  width: 82px;
  height: 45px;
}
.css-fvc8ir-MuiBadge-badge {
  background: #d81923;
  color: white;
}

/* Scrollbar */
*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #e1e3ea !important;
  border-radius: 100px;
}
*::-webkit-scrollbar-thumb {
  background-color: #5e6278 !important;
  border-radius: 100px;
}
.bar-side::-webkit-scrollbar {
  height: 5px;
  width: 2px;
  background-color: var(--bs-app-footer-bg-color) !important;
  border-radius: 100px;
}
.bar-side::-webkit-scrollbar-thumb {
  background-color: var(--bs-app-bg-color) !important;
  width: 2px;

  border-radius: 100px;
}
.popUpPrice {
  display: none !important;
}
.priceOrder:hover + .popUpPrice {
  color: rgb(3, 0, 0);
  display: flex !important;
}

textarea {
  outline: none !important;
}

.tbodyBg tr:nth-of-type(2n) {
  background-color: #f9f9fb !important;
}
